import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useNotification } from '../../../hooks/useNotification';
import { ModalForms } from './ModalForms';
import moment from 'moment';
import * as Icons from '@mui/icons-material';
import { DialogConfirmAction } from '../../dialogs/DialogConfirmAction';

export const LoadButtonHeaderTable = ({ buttons, id_user_action, DocManager, columns, handleChange }) => {
    const [{ userSession }] = useDataAuth();
    const [sendNotification] = useNotification();
    const [idReg, setIdReg] = useState('');
    const [openLoadingOverlay, setopenLoadingOverlay] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const { filters, rowsChecked, handleRowsChecked } = DocManager;

    const CallApi = async (trigger, name, requires_check) => {
        const filt = JSON.stringify({
            "filters": {
                "id_unique": {
                    "value": rowsChecked.join(","),
                    "operator": "IN",
                    "type": "string"
                }
            }
        })
        let ext = name.split('.').pop();
        let bodyData = trigger[0]?.bodyData;
        const url = trigger[0]?.url;
        const method = trigger[0]?.method;
        if (requires_check === 'true') {
            if (rowsChecked.length > 0)
                bodyData.variables = filt
            else {
                sendNotification({ msg: 'Debes selecionar por lo menos una fila', variant: 'error' });
                return false;
            }
        }
        else {
            if (rowsChecked.length > 0)
                bodyData.variables = filt
            else {
                bodyData.variables = JSON.stringify({ filters });
            }
        }

        const header = columns.reduce((acc, col) => {
            if (col.visible)
                acc[col.label] = `{${col.id}}`;
            return acc;
        }, {});

        bodyData.schema = header;
        bodyData.id_user_action = id_user_action;

        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };
        sendNotification({ msg: 'Se esta generando el archivo, esto puede tomar unos segundos!', variant: 'success' });
        handleRowsChecked([]);
        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                const byteCharacters = atob(response.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: `application/${ext}` });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name.replace('{date}', moment().format('YYYY-MM-DDTHH:mm:ssZ')));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else
                sendNotification({ msg: 'Ocurrió un error generando el archivo, por favor comuníquese con el area encargada', variant: 'error' });
        } catch (error) {
            console.error(error);
        }
    }

    const [tittleDiealog, setTittleDiealog] = useState('');
    const [trigger, setTrigger] = useState(null);
    const [requiredC, setRequiredCheck] = useState('');
    const OpenConfirmation = (param, param2, param3) => {
        setOpenConfirmation(true);
        setTrigger(param);
        setRequiredCheck(param2);
        setTittleDiealog(param3);
    }

    const CallApiTrigger = async () => {
        setopenLoadingOverlay(true);
        const filt = JSON.stringify({
            "filters": {
                "id_unique": {
                    "value": rowsChecked.join(","),
                    "operator": "IN",
                    "type": "string"
                }
            }
        })
        let bodyData = trigger[0]?.bodyData;
        const url = trigger[0]?.url;
        const method = trigger[0]?.method;
        bodyData.id_user_action = id_user_action;

        if (requiredC === 'true' && !rowsChecked.length > 0) {
            sendNotification({ msg: 'Debes selecionar por lo menos una fila', variant: 'error' });
            setopenLoadingOverlay(false);
            return false;
        }

        bodyData.variables = filt;//variables || "{}";
        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        console.log('bodyData ', bodyData);
        console.log(JSON.stringify(bodyData));

        try {
            const response = await axios.request(config);
            console.log('response ', response);
            if (response.status === 200) {
                setOpenConfirmation(false);
                sendNotification({ msg: 'Evento realizado correctamente!', variant: 'success' });
                setTimeout(() => {
                    handleChange();
                }, 500);
            }
            else if (response.status === 504 && response.status === 408) {
                sendNotification({ msg: 'El proceso se esta ejecutando', variant: 'info' });
                setOpenConfirmation(false);
            }
            else
                sendNotification({ msg: 'Ocurrio un error realizando la acción, por favor comuniquese con el area encargada!', variant: 'error' });
        } catch (error) {
            if (error.response?.status === 504)
                sendNotification({ msg: 'El proceso se está ejecutando', variant: 'info' });
            else
                sendNotification({ msg: 'Ocurrio un error realizando la acción, por favor comuniquese con el area encargada!', variant: 'error' });
            console.error('ERROR ==> ', error);
        }
        setopenLoadingOverlay(false);
    }

    return (
        <>
            {
                buttons && buttons.length > 0 &&
                buttons.map((item, index) => (
                    <>
                        {
                            item.type === 'download' && (
                                <Tooltip
                                    title={item.accion}
                                    key={index}
                                >
                                    <Box
                                        component="img"
                                        key={index}
                                        src={item.icon}
                                        alt={item.accion}
                                        sx={{
                                            height: 30,
                                            width: 'auto',
                                            cursor: 'pointer',
                                            marginInline: '3px'
                                        }}
                                        onClick={() => CallApi(item.trigger, item.name_file, item.requires_check)}
                                    />
                                </Tooltip >
                            )
                        }
                        {
                            item.type === 'form' && (
                                <ModalForms data={item} id_user_action={id_user_action} handleChange={handleChange} />
                            )
                        }
                        {
                            item.type === 'trigger' && (
                                <Tooltip title={item.accion}>
                                    <Box
                                        component="img"
                                        key={item.accion}
                                        src={item.icon}
                                        alt={item.accion}
                                        sx={{
                                            height: 30,
                                            width: 'auto',
                                            cursor: 'pointer',
                                            marginInline: '3px'
                                        }}
                                        onClick={() => OpenConfirmation(item.trigger, item.requires_check, item.accion)}
                                    />
                                </Tooltip >
                            )
                        }
                    </>
                ))
            }
            <DialogConfirmAction
                openDialogConfirm={openConfirmation}
                openLoadingOverlay={openLoadingOverlay}
                setOpenDialogConfirm={() => setOpenConfirmation(false)}
                titleDialog={tittleDiealog}
                textConfirmAction={"¿Esta seguro que desea continuar?"}
                textButtonConfirm={"Aceptar"}
                textButtonCancel={"Cancelar"}
                handleConfirmAction={CallApiTrigger}
            />
        </>
    )
}